import React, { useContext, useState, useRef, useEffect } from 'react';
import Layout from '../components/Layout';
import { AppContext } from '../services/AppContext';
import ShareButtons from '../components/ShareButtons';
import ContestImage from "../components/ContestImage";
import Logo from '../assets/images/okc-dodgers.png';

const Winner = () => {
  const linkRef = useRef(null);
  const copyToClipboard = (e) => {
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };
  const {
    contestDetails,
    contestId,
    getWinner,
  } = useContext(AppContext);
  
  const [winnerUrl, setWinnerUrl] = useState(null);
  const [winnerName, setWinnerName] = useState(null);
  
  useEffect(() => {
    getWinner().then((doc) => {
      setWinnerUrl(doc[0].url);
      setWinnerName(doc[0].name);
    });
  }, []);

  const Congratulations = () => {
    return (
      <>
        Congratulations <span className="font-bold">{winnerName}</span>!
      </>
    )
  }

  return (
    <Layout>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-6 flex flex-col flex-1 h-full justify-between">
          <div className="text-slate flex justify-between items-center">
            <div>
              <img alt="" src={Logo} />
            </div>
            <div className="uppercase text-xl md:text-4xl -mt-1">WINNER</div>
          </div>
          <div>
            <h2 className="text-slate font-semibold text-2xl md:text-3xl my-4 text-center">
              {
                contestDetails.winnerAnnounced
                ? <Congratulations />
                : `Winner will be announced here soon`
              }
            </h2>
            {/* <ShareButtons /> */}
          </div>

          <div className="flex justify-between items-center">
            {/* This element needed for the copy to clipboard */}
            <textarea
              type="link"
              ref={linkRef}
              style={{ height: 0, width: 0, opacity: 0, padding: 0 }}
              defaultValue={`${window.location.origin}/winner`}
            >
            </textarea>
            <a
              href={`${window.location.origin}/winner`}
              target="_blank"
              className="text-coolGrey"
              rel="noreferrer"
            >
              {window.location.origin}/winner
            </a>
            <button onClick={copyToClipboard}>
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  fill="#1b3668"
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="p-6 bg-lightGray">
          {
            contestDetails.winnerAnnounced
            ? <img src={winnerUrl} className="relative top-0 z-6" alt="" />
            : <ContestImage />
          }
          
        </div>
      </div>
    </Layout>
  );
};

export default Winner;
