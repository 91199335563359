import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../services/AppContext'

const VoteEmailForm = ({entryId}) => {
  const { saveVoter, voteFor, signUpError } = useContext(AppContext);
  const initialErrorState = {
    name: false,
    email: false,
  };
  const [values, setValues] = useState({
    name: '',
    email: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState(initialErrorState);
  
  const initialField = {value: '', error: false};
  const [nameField, setNameField] = useState(initialField);
  const [emailField, setEmailField] = useState(initialField);
  
  const validateFields = () => {
    let valid = true;
    if (nameField.value === '') {
      setNameField({
        value: '',
        error: true,
      });
      valid = false;
    }
    if (emailField.value === '' || !validEmail(emailField.value)) {
      setEmailField({
        value: emailField.value,
        error: true,
      });
      valid = false;
    }
    return valid;
  }
  const handleSubmit = (ev) => {
    
    // SUBMIT
    
    ev.preventDefault();
    if (validateFields()) {
      saveVoter({
          name: nameField.value,
          email: emailField.value,
        })
        .then(voteFor(entryId));
    }
  };
  const validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  return (
    <form>
      <div className="p-6 flex flex-col flex-1 h-full justify-between">
        <div className="pr-6">
            <div className="space-y-6 mt-4">
            <div>
                <input
                type="text"
                name="name"
                id="name"
                value={nameField.value}
                placeholder="NAME"
                className="form-input w-full"
                onChange={(ev) => setNameField({
                  value: ev.target.value,
                  error: false,
                })}
                />
                {nameField.error && (
                  <small className="text-red">
                    Name is required
                  </small>
                )}
            </div>

            <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(ev) => setEmailField({
                    value: ev.target.value,
                    error: false,
                  })}
                  value={emailField.value}
                  placeholder="EMAIL"
                  className="form-input w-full"
                  />
                  {emailField.error && (
                    <small className="text-red">
                      A valid email is required
                    </small>
                  )}
              </div>
            </div>
        </div>

        <div className="text-center mt-3">
            <p className="text-red">
            {/* This can be changed for a material Alert */}
            {signUpError}
            </p>
            <button
            type="button"
            disabled={false}
            onClick={(ev) => {
              handleSubmit(ev);
            }}
            className={`bg-red text-white px-3 py-2 text-lg uppercase mx-auto font-bold inline-block`}
            >
            Boost my vote
            </button>
        </div>
      </div>
    </form>
  );
};

export default VoteEmailForm;
