import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from '../services/AppContext';

const Footer = () => {
  const { appState, voteEnabled } = useContext(AppContext);
  const { uploadComplete, userEmail } = appState;
  return (
    <div className="grid grid-cols-1">
      <div className="p-12 flex flex-row justify-center space-x-3 items-center border-t text-sm">
        { voteEnabled && !userEmail ?
        <div>
          <Link to="/home">Participate</Link>
        </div>
        : null }
        { voteEnabled && userEmail && !uploadComplete ?
        <div>
          <Link to="/upload">Participate</Link>
        </div>
        : null }
        <div>
          <Link to="/entries">All entries</Link>
        </div>
        <div>
          <Link to="/finalists">Finalists</Link>
        </div>
        <div>
          <Link to="/terms">Terms</Link>
        </div>
      </div>
    </div>
  );
}
export default Footer;
