import { Link } from "react-router-dom";

const FinalistsLink = () => {
  return (
    <div className="flex justify-between items-center py-4">
      <Link to="/finalists"
        className={`border-2 border-red text-red px-3 py-2 text-sm uppercase mx-auto font-bold inline-block`}
      >
        CHECK THE FINALISTS PAGE
      </Link>
    </div>
  )
}

export default FinalistsLink;
