const Counter = ({ date }) => {
  // const finalDate = date || new Date()
  return (
    <div className="p-1 w-52 mx-auto bg-superGreen">
      <div className="p-1 border-white border-2 text-white uppercase text-center text-2xl font-bold">
        Time
        <div className="mb-1 space-x-1  text-xl mt-2">
          <span className="p-1.5 bg-coolGrey">1</span>
          <span className="p-1.5 bg-coolGrey">5</span>
          <span>:</span>
          <span className="p-1.5 bg-coolGrey">1</span>
          <span className="p-1.5 bg-coolGrey">5</span>
          <span>:</span>
          <span className="p-1.5 bg-coolGrey">1</span>
          <span className="p-1.5 bg-coolGrey">5</span>
        </div>
      </div>
    </div>
  );
};

export default Counter;
