import React from "react";
import ModalWrapper from "../components/ModalWrapper";

const ProcessingEntryModal = ({show}) => {
  return (
    <ModalWrapper
      show={show}
      className="max-w-screen-md"
    >
      <div className="text-center  text-coolGrey">
        <div className="text-coolGrey space-y-6 p-6">
          <div className="flex justify-around items-center">
            <h2 className="font-bold text-xl md:text-3xl uppercase">
              THANK YOU FOR SUBMITTING <br /> YOUR PHOTO!
            </h2>
          </div>
          <div className="text-lg ">
            <p>
              Your photo is being vetted. <br /> You will be redirected to the
              voting page shortly.
            </p>
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 489.533 489.533"
              className="w-20 h-20 mx-auto my-4"
            >
              <g>
                <path
                  fill={"#eb1c2d"}
                  d="M268.175,488.161c98.2-11,176.9-89.5,188.1-187.7c14.7-128.4-85.1-237.7-210.2-239.1v-57.6c0-3.2-4-4.9-6.7-2.9
      l-118.6,87.1c-2,1.5-2,4.4,0,5.9l118.6,87.1c2.7,2,6.7,0.2,6.7-2.9v-57.5c87.9,1.4,158.3,76.2,152.3,165.6
      c-5.1,76.9-67.8,139.3-144.7,144.2c-81.5,5.2-150.8-53-163.2-130c-2.3-14.3-14.8-24.7-29.2-24.7c-17.9,0-31.9,15.9-29.1,33.6
      C49.575,418.961,150.875,501.261,268.175,488.161z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
            <p>Taking too long? Retry</p>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
  
};

export default ProcessingEntryModal;
