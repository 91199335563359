import React, { useContext } from "react";
import { AppContext } from '../services/AppContext';

const SessionWidget = () => {
  const { appState, clearContestId, clearSession } = useContext(AppContext);
  const { userEmail, userName } = appState;
  const clearStorage = (ev) => {
    clearSession();
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return null;
  }

  if (!userEmail) {
    return null;
  }

  const renderName = () => {
    if (userName) {
      return <>{ userName }&nbsp;</>;
    }
    return null;
  }
  return (
    <p className="flex justify-end bg-white bg-opacity-75 text-xs p-1">
      Hello { renderName() }
      |&nbsp;
      <button onClick={ clearContestId } className="text-lightBlue font-bold">
        Clear contestId
      </button>
      |&nbsp;
      <button onClick={ clearStorage } className="text-lightBlue font-bold">
        Clear session
      </button>
    </p>
  )
}

export default SessionWidget;