import React from "react";

const ModalWrapper = ({ children, show, close, className }) => (
  <div
    className={`fixed z-10 inset-0 overflow-y-auto ${!show && "hidden"} `}
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 z-10">
      <div
        onClick={close}
        className="fixed inset-0 bg-slate bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        className="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div
        className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  container  sm:w-full ${
          className ?? ""
        }`}
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div>{children}</div>
        </div>
      </div>
    </div>
  </div>
);
export default ModalWrapper;
