import React, { useContext } from "react";
import { AppContext } from '../services/AppContext';

const ContestImage = (eventId) => {

  const { contestDetails } = useContext(AppContext);
  
  return (
    <img src={contestDetails.awardImage} alt="Welcome" />
  );
}

export default ContestImage;