import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from 'luxon';
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import ModalWrapper from "../components/ModalWrapper";
import VoteEmailForm from "../components/VoteEmailForm";
import Logo from "../assets/images/okc-dodgers.png";
import Deadline from "../components/Deadline";
import { AppContext } from '../services/AppContext'


const Vote = () => {
  const { entryId } = useParams();
  const {
    appState,
    contestDetails,
    getEntry,
    voteFor,
    voteEnabled,
  } = useContext(AppContext);
  const dateTime = DateTime.fromSeconds(parseInt(contestDetails.finalStartTimestamp,10));

  const { voteComplete, voteLevel } = appState;

  const [voteSuccess, setVoteSuccess] = useState(false);
  const [voteError, setVoteError] = useState(false);
  const [voteMax, setVoteMax] = useState(false);
  const [entryName, setEntryName] = useState('');
  const [entryError, setEntryError] = useState(false);
  const [entryUrl, setEntryUrl] = useState('');
  
  const handleVote = () => {
    if (voteLevel ===  1) {
      // Already voted once, offer to boost with email
      setVoteSuccess(true);
    } else if (voteLevel === null) {
      // Has not voted from this browser
      voteFor(entryId)
        .then(() => {
          setVoteSuccess(true);
        })
        .catch((error) => {
          setVoteError(true);
        });
    } else {
      setVoteMax(true);
    }
  }

  useEffect(()=>{
    getEntry(entryId)
      .then( (entry) => {
        // setEntryName(entry.name);
        if (!entry) {
          setEntryError(true);
        } else {
          setEntryName(entry.name);
          setEntryUrl(entry.url);
        }
      });
  },[]);
  if (!voteEnabled) {
    return (
      <Layout>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-6 flex flex-col flex-1 h-full justify-between">
            <div className="text-slate flex justify-between items-center">
              <div>
                <img alt="" src={Logo} />
              </div>
              <div className="uppercase text-xl md:text-3xl -mt-1">
                {entryName}
              </div>
            </div>
            <div>
              <Deadline headerText="Voting ends on" timestamp={ contestDetails.uploadEndTimestamp } />
            </div>
            <h2 className="text-slate font-bold text-xl md:text-2xl my-4 text-center">
            Voting has ended
            </h2>
            <p>
            The contest you're trying to access does not accept more votes.
            </p>
            <Link
              to="/finalists"
              className={`border-2 border-red text-red px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
            >
              PREVIEW FINALISTS
            </Link>
          </div>
          <div className="p-6 bg-lightGray">
            <img src={entryUrl} className="relative top-0 z-6" alt="" />
          </div>
        </div>
      </Layout>
    );
  }
  if (entryError) {
    return (
      <Layout>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-6 flex flex-col flex-1 h-full justify-between">
            <h2 className="font-bold text-xl md:text-3xl uppercase">
              Invalid entry
            </h2>
            <p>
              The contest entry you're trying to access does not exist or has not been accepted.
            </p>
          </div>
        </div>
      </Layout>
    )
  }
  if (voteLevel === 10) {
    return (
      <Layout>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-6 flex flex-col flex-1 h-full justify-between">
            <div className="text-slate flex justify-between items-center">
              <div>
                <img alt="" src={Logo} />
              </div>
              <div className="uppercase text-xl md:text-3xl -mt-1">
                {entryName}
              </div>
            </div>
            <div>
              <Deadline headerText="Voting ends on" timestamp={ contestDetails.uploadEndTimestamp } />
            </div>
            <h2 className="text-slate font-bold text-xl md:text-2xl my-4 text-center">
              You've already voted on this contest.
            </h2>
            <Link
              to="/finalists"
              className={`border-2 border-red text-red px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
            >
              PREVIEW FINALISTS
            </Link>
          </div>
          <div className="p-6 bg-lightGray">
            <img src={entryUrl} className="relative top-0 z-6" alt="" />
          </div>
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-6 flex flex-col flex-1 h-full justify-between">
          <div className="text-slate flex justify-between items-center">
            <div>
              <img alt="" src={Logo} />
            </div>
            <div className="uppercase text-xl md:text-3xl -mt-1">
              {entryName}
            </div>
          </div>
          <div>
            <Deadline headerText="Voting ends on" timestamp={ contestDetails.uploadEndTimestamp } />
          </div>
          <h2 className="text-slate font-bold text-xl md:text-2xl my-4 text-center">
            Help {entryName} winning by sharing their photo and
            add 10 more points by entering your email address
          </h2>

          <button
            onClick={handleVote}
            className={`bg-red text-white px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
          >
            VOTE FOR THIS PHOTO
          </button>
        </div>
        <div className="p-6 bg-lightGray">
          <img src={entryUrl} className="relative top-0 z-6" alt="" />
          <div className="flex justify-between items-center py-6">
            <Link to="/entries"
              className={`border-2 border-red text-red px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
            >
              SEE ALL ENTRIES
            </Link>
          </div>
        </div>
      </div>
      <ModalWrapper
        show={voteSuccess}
        close={() => setVoteSuccess(false)}
        className="max-w-screen-md"
      >
        <div className="text-center  text-coolGrey">
          <div className="text-coolGrey space-y-8 p-6 text-lg">
            <h2 className="font-bold text-xl md:text-3xl uppercase">
              Help {entryName} win more votes!
            </h2>
            <p>
              Your vote has been recorded
            </p>
            <p>
              OKCD will share the finalists of the photo contest on {
              dateTime.toLocaleString(DateTime.DATETIME_FULL)
              } on the <Link className="text-xl md:text-2xl text-red" to="/finalists">Finalists Page</Link>
            </p>
            <p>
              Multiply your vote x 10 by entering your name and email and stay up to date
              about new contests and opportunities to win amazing prices!
            </p>
            <VoteEmailForm entryId={entryId} />
            <button
              type="submit"
              onClick={() => {
                setVoteSuccess(false);
                // history.push("/semifinals");
              }}
              className={`border-2 border-red text-red px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
            >
              Close
            </button>
          </div>
        </div>
      </ModalWrapper>
      <ModalWrapper
        show={voteMax}
        close={() => setVoteMax(false)}
        className="max-w-screen-md"
      >
        <div className="text-center  text-coolGrey">
          <div className="text-coolGrey space-y-8 p-6 text-lg">
            <h2 className="font-bold text-xl md:text-3xl uppercase">
              Already voted
            </h2>
            <p>
              Your vote could not be recorded
            </p>
            <button
              type="submit"
              onClick={() => {
                setVoteMax(false);
              }}
              className={`border-2 border-red text-red px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
            >
              Close
            </button>
          </div>
        </div>
      </ModalWrapper>
      <ModalWrapper
        show={voteError}
        close={() => setVoteError(false)}
        className="max-w-screen-md"
      >
        <div className="text-center  text-coolGrey">
          <div className="text-coolGrey space-y-8 p-6 text-lg">
            <h2 className="font-bold text-xl md:text-3xl uppercase">
              There was a problem
            </h2>
            <p>
              Your vote could not be recorded
            </p>
            <button
              type="submit"
              onClick={() => {
                setVoteError(false);
              }}
              className={`border-2 border-red text-red px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
            >
              Close
            </button>
          </div>
        </div>
      </ModalWrapper>
    </Layout>
  );
};

export default Vote;
