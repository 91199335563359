import React, {useContext } from "react";
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import { AppContext, AppContextProvider } from './services/AppContext';
import Layout from './components/Layout';
import Home from "./pages/Home";
import SignUpForm from "./pages/SignUpForm";
import UploadPhoto from "./pages/UploadPhoto";
import Share from "./pages/Share";
import Vote from "./pages/Vote";
import Entry from "./pages/Entry";
import Winner from "./pages/Winner";
import Timeout from "./pages/Timeout";
import Finalists from "./pages/Finalists";
import "./App.css";
import AllEntries from "./pages/AllEntries";
import Terms from "./pages/Terms";

const RootRedirects = () => {
  
  const { appState, finalEnabled, voteEnabled, winnerAnnounced } = useContext(AppContext);

  if (winnerAnnounced) {
    return <Redirect to="/winner" />
  }
  if (!voteEnabled && !winnerAnnounced) {
    return <Redirect to="/finalists" />
  }
  if (voteEnabled) {
    return <Redirect to="/home" />
  }
}
const HomeRedirects = () => { 
  const { appState, finalEnabled, voteEnabled, winnerAnnounced } = useContext(AppContext);
  const { userId, uploadComplete } = appState;
  if (winnerAnnounced) {
    return <Redirect to="/winner" />
  }
  if (!voteEnabled && !winnerAnnounced) {
    return <Redirect to="/finalists" />
  }
  if (voteEnabled && uploadComplete) {
    return <Redirect to="/entries" />
  }
  if (voteEnabled && userId) {
    return <Redirect to="/upload" />
  }
  return <Home />
}

const StartRedirects = () => { 
  const { appState, finalEnabled, voteEnabled, winnerAnnounced } = useContext(AppContext);
  const { userId, uploadComplete } = appState;
  if (winnerAnnounced) {
    return <Redirect to="/winner" />
  }
  if (voteEnabled && userId) {
    return <Redirect to="/upload" />
  }
  return <SignUpForm />
}

const UploadRedirects = () => { 
  const { appState, finalEnabled, voteEnabled, winnerAnnounced } = useContext(AppContext);
  const { userId, uploadComplete } = appState;
  if (winnerAnnounced) {
    return <Redirect to="/winner" />
  }
  if (voteEnabled && !userId) {
    return <Redirect to="/start" />
  }
  if (voteEnabled && uploadComplete) {
    return <Redirect to="/share" />
  }
  if (!voteEnabled) {
    return <Redirect to="/finalists" />
  }
  return <UploadPhoto />
}

const ConditionalRoute = () => {

  const { appState, finalEnabled, voteEnabled, winnerAnnounced } = useContext(AppContext);
  if (appState === null) {
    return <div>Loading...</div>;
  }
  const { userId, uploadComplete } = appState;
  
  

  return(
    <Switch>
      {/*
        REDIRECTIONS BASED ON CONTEST AND USER STATE
      */}
      <Route path="/" exact>
        <RootRedirects />
      </Route>
      <Route path="/home" exact>
        <HomeRedirects />
      </Route>
      <Route path="/start" exact>
        <StartRedirects />
      </Route>
      <Route path="/upload" exact>
        <UploadRedirects />
      </Route>
      <Route path="/share" exact>
        { !uploadComplete ? <Redirect to="/upload" /> : <Share />}
      </Route>
      {/*
        REDIRECTIONS BASED ON CONTEST STATE FOR THE ROUTES BELOW
        IS HANDLED BY EACH COMPONENT
      */}
      <Route path="/vote/:entryId" exact>
        <Vote />
      </Route>
      <Route path="/entry/:entryId" exact>
        <Entry />
      </Route>
      <Route path="/winner" exact>
        <Winner />
      </Route>
      <Route path="/timeout/:id" exact>
        <Timeout />
      </Route>
      <Route path="/entries" exact>
        <AllEntries />
      </Route>
      <Route path="/finalists" exact>
        <Finalists />
      </Route>
      <Route path="/terms" exact>
        <Terms />
      </Route>
      <Route path="*">
        <Layout>
          <h1>404 error</h1>
        </Layout>
      </Route>
    </Switch>
  );
};

function App() {
  
  return (
    <Router>
      <AppContextProvider>
        <ConditionalRoute />
      </AppContextProvider>
    </Router>
  );
}

export default App;
