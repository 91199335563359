import React, { useContext, useRef, useEffect, useState } from "react";
import { DateTime } from 'luxon';
import { getAllEntriesOnSnapshot } from '../services/FirestoreService';
import ModalWrapper from "../components/ModalWrapper";
import Layout from "../components/Layout";
import Deadline from "../components/Deadline";
import EntriesList from "../components/EntriesList";
import WinnerLink from "../components/WinnerLink";
import FinalistsLink from "../components/FinalistsLink";
import ShareButtons from "../components/ShareButtons";
import Logo from "../assets/images/okc-dodgers.png";
import { AppContext } from '../services/AppContext'

const AllEntries = () => {
  const now = DateTime.now().toSeconds();
  const [voted, setVoted] = useState(null);
  const [finalistsList, setFinalistsList] = useState([]);
  const linkRef = useRef(null);
  const {
    contestDetails,
    contestId,
  } = useContext(AppContext);
  const copyToClipboard = (e) => {
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };
  const shareLink = `${window.location.origin}/finalists`;

  useEffect(() => {
    const unsubscribe = getAllEntriesOnSnapshot(contestId, (querySnapshot) => {
      var entries = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          entries.push({
            ...doc.data(),
            id
          });
        });
        setFinalistsList(entries);
    });
    return unsubscribe;
  }, [contestId, setFinalistsList]);

  return (
    <Layout maxSize="md:max-w-screen-xl">
      <div className="text-left">
        <div className="p-4">
          <div className="grid grid-cols-2 md:grid-cols-3 flex justify-between mx-4 my-2">
            <div>
              <img alt="" src={Logo} />
            </div>
            <div className="text-slate flex-col flex items-center space-x-3">
              <div className="uppercase text-xl md:text-2xl -mt-1">
                All Entries
              </div>
            </div>
            <div className="hidden md:block">
              <Deadline headerText="Finals start on" timestamp={ contestDetails.finalStartTimestamp } />
            </div>
          </div>
          
          {/* MOBILE */}
          <div className="grid grid-cols-1 md:hidden">  
            <div className="m-4 flex flex-col justify-around text-center">
            <FinalistsLink />
              <Deadline headerText="Finals start on" timestamp={ contestDetails.finalStartTimestamp } />
            </div>
          </div>
          
          {/* DESKTOP */}
          <div className="grid grid-cols-3 md:grid-cols-5">
            <div className="m-4 flex flex-col justify-around hidden md:block">
            <FinalistsLink />
            </div>
            <EntriesList entries={finalistsList} opens="vote" />
          </div>
        </div>
        {/* This element needed for the copy to clipboard */}
        <textarea
            type="link"
            ref={linkRef}
            style={{ height: 0, width: 0, opacity: 0, padding: 0 }}
            defaultValue={shareLink}
          >
        </textarea>
        <div className="mx-auto text-center flex justify-center pb-6">
          <div className="flex items-center text-center  space-x-3">
            <a
              href={shareLink}
              target="_blank"
              className="text-coolGrey text-lg"
              rel="noreferrer"
            >
              { shareLink }
            </a>
            <button onClick={copyToClipboard}>
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  fill="#1b3668"
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllEntries;
