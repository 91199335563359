import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigStg = {
  apiKey: "AIzaSyD5YEmLwjEQQtWJ08LODxiFzxddesnXM-8",
  authDomain: "kisscam-stg.firebaseapp.com",
  projectId: "kisscam-stg",
  storageBucket: "kisscam-stg.appspot.com",
  messagingSenderId: "386777356445",
  appId: "1:386777356445:web:2289c052d66ada4d3e5c74",
  measurementId: "G-T1Z983QVQ9"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyB839i3BkSu99KzsygRilPniomge61Phes",
  authDomain: "kisscamdev-ecc0b.firebaseapp.com",
  databaseURL: "https://kisscamdev-ecc0b.firebaseio.com",
  projectId: "kisscamdev-ecc0b",
  storageBucket: "kisscamdev-ecc0b.appspot.com",
  messagingSenderId: "716406591788",
  appId: "1:716406591788:web:98bf8ec45ae8cb13fbdc89",
  measurementId: "G-T3H3YQCLJ2"
};


if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  firebase.initializeApp(firebaseConfigProd);
} else {
  firebase.initializeApp(firebaseConfigStg);
}
firebase.storage();
export default firebase;
