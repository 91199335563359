import firebase from '../firebase.config';
import { appConfig } from '../App.config';
import { DateTime } from 'luxon';

export const getActiveContest = () => {
  return firebase.firestore()
    .collection('tenants')
    .doc(`${appConfig.tenant}`)
    .collection('contests')
    .where('active', '==', true)
    .get()
};

export const saveVoterEmailName = ({contestId, email, name}) => {
  const now = DateTime.now().toSeconds();
  return firebase.firestore()
    .collection('tenants')
    .doc(`${appConfig.tenant}`)
    .collection('contests')
    .doc(`${contestId}`)
    .collection('voters')
    .add({
      email,
      name,
      createdTimestamp: Math.round(now),
      updatedTimestamp: Math.round(now),
    });
};

export const getFinalistsOnSnapshot = (contestId, observer) => {
  return firebase.firestore()
    .collection('tenants')
    .doc(`${appConfig.tenant}`)
    .collection('contests')
    .doc(`${contestId}`)
    .collection('finalists')
    // .orderBy('votes', 'desc')
    // .limit(9)
    .onSnapshot(observer);
};

export const getAllEntriesOnSnapshot = (contestId, observer) => {
  return firebase.firestore()
    .collection('tenants')
    .doc(`${appConfig.tenant}`)
    .collection('contests')
    .doc(`${contestId}`)
    .collection('accepted')
    .orderBy('updatedTimestamp', 'desc')
    .onSnapshot(observer);
};