import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import Logo from "../assets/images/okc-dodgers.png";
import ContestImage from "../components/ContestImage";
import { AppContext } from '../services/AppContext'

const Home = (eventId) => {
  
  // Event ID commented out and read from appConfig
  // This needs to change for something more flexible, so leaving the lines below
  //  in case we go back to the URL param solution

  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const eventId = query.get('event');
  // console.log(`eventId: ${eventId}`);

  const { contestDetails } = useContext(AppContext);
  
  return (
    <Layout>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-6 flex flex-col justify-between items-center space-y-4 md:space-y-0">
          <div>
            <div className="text-slate flex justify-between items-center">
              <div>
                <img alt="" src={Logo} />
              </div>
              <div className="uppercase text-xl md:text-3xl -mt-1 text-right">
                { contestDetails.name }
              </div>
            </div>
            <h2 className="text-slate font-bold text-xl md:text-3xl my-4">
              {contestDetails.description}
            </h2>
            <div>
              <p className="text-black text-base md:text-lg text-coolGrey">
                { contestDetails.instructions }
              </p>
            </div>
          </div>

          <div className="text-center">
            <Link
              className="bg-red text-white px-3 py-2 text-lg uppercase mx-auto font-bold"
              to="/start"
            >
              ENTER THE CONTEST
            </Link>
          </div>
        </div>
        <div className="p-6 flex flex-col justify-between items-center space-y-4 md:space-y-0 bg-lightGray">
          <ContestImage />
          <div className="flex justify-between items-center py-6">
            <Link to="/entries"
              className={`border-2 border-red text-red px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
            >
              SEE ALL ENTRIES
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
