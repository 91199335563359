import { Link } from "react-router-dom";
import SessionWidget from './SessionWidget';
import Footer from "./Footer";

const Layout = ({ children, maxSize }) => (
  <div
    className={`container mx-auto my-10 md:my-32 font-lato px-3 md:px-0 ${
      maxSize ? maxSize : "md:max-w-screen-lg"
    }`}
  >
    <SessionWidget />
    <div className="wrapper bg-white">
      {children}
      <Footer />
    </div>
  </div>
);

export default Layout;
