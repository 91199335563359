import { Link } from "react-router-dom";

const EntriesList = ({ entries, opens='entry' }) => {
  return (
    <>
    {entries.map((item, index) => {
      // opens: vote || entry
      // Used for the URL each entry points to
      return (
        <div className=" m-4 relative" key={item.id}>
          <Link to={`/${opens}/${item.id}`}>
            <img src={item.url} className="relative top-0 z-10" alt="" />
          </Link>
          <div className="
            text-center
            w-full
            bg-superGreen
            text-white
            px-1
            py-1
            mt-1
            text-sm
            uppercase
            mx-auto
            font-bold
            inline-block">
            {item.votes} votes
          </div>
        </div>
      );
    })}
    </>
  )
}

export default EntriesList;