import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import Logo from "../assets/images/okc-dodgers.png";
import Cover from "../assets/images/image-cover.png";
// import Image from "../assets/images/intersection-1.jpg";
import Counter from "../components/Counter";
import ShareButtons from "../components/ShareButtons";

const Image = 'https://cdn.shopify.com/s/files/1/0263/7591/3558/products/DodgersHat_7_5184x.jpg?v=1596047078';

const Timeout = () => {
  const linkRef = useRef(null);
  const copyToClipboard = (e) => {
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };

  return (
    <Layout>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-6 flex flex-col flex-1 h-full justify-between">
          <div className="text-slate flex justify-between items-center">
            <div>
              <img alt="" src={Logo} />
            </div>
            <div className="uppercase text-xl md:text-3xl -mt-1">
              John Stevens
            </div>
          </div>
          <div>
            <Counter />
          </div>
          <h2 className="text-slate font-bold text-xl md:text-2xl my-4 text-center">
            Time to vote for John’s photo is out. You can still share it with
            friends.
          </h2>
          <ShareButtons />

          <div className="text-center">
            <h2 className="text-slate font-bold text-xl md:text-2xl my-4 text-center">
              Check if John made it to the semifinalists:
            </h2>
            <p>
              <Link className="text-xl md:text-2xl text-red" to="/semifinals">
                https://contestdomain/SEMIFINALS
              </Link>
            </p>
          </div>

          <div className="flex justify-between items-center">
            <a
              href="http://customdomain.com/id"
              target="_blank"
              className="text-coolGrey"
              rel="noreferrer"
            >
              http://customdomain.com/id
            </a>
            <button onClick={copyToClipboard}>
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  fill="#1b3668"
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="p-6 bg-lightGray">
          <div
            className="relative bg-center bg-cover"
            style={{
              backgroundImage: `url(${Image})`,
            }}
          >
            <img src={Cover} className="relative top-0 z-6" alt="" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Timeout;
