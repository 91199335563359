import { DateTime } from 'luxon';

const Deadline = ({ timestamp, headerText }) => {
  const dateTime = DateTime.fromSeconds(parseInt(timestamp,10));
  return (
    <div className="p-1 w-100 mx-auto bg-superGreen">
      <div className="p-1 border-white border-2 text-white text-center text-xl font-bold">
        { headerText }
        <div className="mb-1 space-x-1 text-sm mt-2">
          <span className="p-1.5 bg-coolGrey">
            { dateTime.toLocaleString(DateTime.DATETIME_FULL) }
          </span>
        </div>
      </div>
    </div>
  );
};

export default Deadline;
